'use strict';

$(document).ready(function () {
    var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        spaceBetween: 0,
        hashnav: true,
        keyboardControl: true,
        hashnavWatchState: true,
        centeredSlides: true
    });
});